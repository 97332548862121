@charset 'utf-8';

.contact {
  .container {
    padding: 0 100px;

    .h2-page {
      margin-bottom: 10px;
    }

    .inquiry {
      .inquiry__numbers {
        display: block;

        .tels {
          .tels__tel-number {
            .telLink {
              color: $color-default;
            }

            .tels__time {
              @include font-setting(1.4rem, 1.8);
            }
          }
        }
      }
    }

    .table {
      tbody {
        tr {
          .form-control {
            font-size: 1.4rem;
          }

          td {
            position: relative;

            .sng-email-alert {
              color: $color-red01;
              font-weight: bold;
              margin-left: 1em;
              transition-property: opacity;
              position: absolute;
              top: 20px;
              z-index: -1;
            }

            .sng-alert-show {
              height: 100%;
              display: block;
              transition-property: opacity;
              position: relative;
              top: auto;
            }

            #email {
              z-index: 2;
            }
          }
        }
      }
    }
  }

  // ---------------------------------------------------------------------------- //
  // ------------------------------- screen-xs-max ------------------------------ //
  // ---------------------------------------------------------------------------- //
  @media (max-width: $screen-xs-max) {
    .container {
      padding: 0 15px;

      table {
        tbody {
          tr {
            th,
            td {
              display: block;
            }

            th {
             background-color: #f9f9f9;
            }
          }
        }
      }

      .table>tbody>tr>td,
      .table>tbody>tr>th,
      .table>tfoot>tr>td,
      .table>tfoot>tr>th,
      .table>thead>tr>td,
      .table>thead>tr>th {
        padding: 10px 13px;
        border-bottom: none;
        border-left: none;
        border-right: none;
      }

      .table tr:first-child th {
        border-top: 0;
      }
    }

    .form-control {
      height: 35px;
    }

    .btn-lg {
      font-size: 1.8rem;
    }

    .textarea {
      height: 100px;
    }

    .inquiry {
      .inquiry__numbers {
        .tels {
          .tels__tel-number {
            .telLink {
              @include font-setting(1.6rem, 1.8);
            }

            .tels__time {
              @include font-setting(1.2rem, 1.8);
            }
          }
        }
      }
    }
  }
}
