@charset 'utf-8';

.top {
  .carousel {
    margin-bottom: 30px;
    max-height: 400px;

    .carousel-caption {
      background-color: rgba(43, 88, 214, 0.34);
      font-size: 3rem;
      font-weight: 700;
      line-height: 1.2;
      padding-bottom: 20px;
    }

    .carousel-indicators {
      bottom: -25px;
    }

    .carousel-inner {
      max-height: 400px;

      .item {
        max-height: 400px;
        text-align: center;

        .logo {
          margin: 0 auto;
          max-height: 400px;
        }
      }
    }
  }

  main {
    .message {
      background-color: $color-gray01;
      margin-bottom: 70px;
      padding: 40px 0;

      .container {
        h1 {
          color: $color-corporate01;
          font-size: 3.4rem;
          font-weight: 700;
        }

        section {
          p {
            font-weight: 700;

            &:last-of-type {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }

  .message {
    background-color: $color-gray01;
    padding: 40px 0;

    .container {
      h1 {
        color: $color-corporate01;
        font-size: 3.4rem;
        font-weight: 700;
      }

      section {
        p {
          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .contents {
    margin-bottom: 150px;

    .row {
      section {
        a {
          color: $color-default;
          display: inline-block;
          position: relative;

          img {
            margin-bottom: 10px;
          }

          h2 {
            @include font-setting(1.8rem, 1);
            border-radius: 0;
            width: 100%;
          }

          &::before {
            border-color: $color-corporate02 transparent transparent transparent;
            border-style: solid;
            border-width: 20px 20px 0 0;
            content: '';
            height: 0;
            position: absolute;
            top: 0;
            width: 0;
            z-index: 1;
          }
        }
      }
    }
  }

  .info {
    h2 {
      @include font-setting(1.8rem, 1, 700);
      border-bottom: 1px solid $color-gray02;
      padding-bottom: 5px;
    }

    .info__contents {
      @include font-setting(1.4rem, 1.8);
      padding-left: 17px;

      .info__contents__content {
        a {
          color: $color-default;
          text-decoration: underline;

          .date {
            font-style: italic;
            margin-right: 7px;
          }

          .txt {
            color: $color-black01;
          }
        }
      }
    }
  }
}

// ---------------------------------------------------------------------------- //
// ------------------------------- screen-xs-max ------------------------------ //
// ---------------------------------------------------------------------------- //
@media (max-width: $screen-xs-max) {
  .top {
    .carousel {
      margin-bottom: 15px;

      .carousel-caption {
        font-size: 1.6rem;
      }
    }

    main {
      .message {
        margin-bottom: 15px;
        padding: 20px 0;

        .container {
          h1 {
            color: $color-corporate01;
            font-size: 2.4rem;
          }

          section {
            p {
              font-size: 1.4rem;
            }
          }
        }
      }
    }

    .contents {
      margin-bottom: 75px;

      .row {
        section {
          a {
            img {
              margin-bottom: 0;
            }
          }

          &:not(:last-child) {
            margin-bottom: 20px;
          }
        }

        .col-xs-6 {
          &:nth-child(2n) {
            padding-left: 7px;
          }

          &:nth-child(2n + 1) {
            padding-right: 7px;
          }
        }
      }
    }
  }

  .spN01 {
    display: none;
  }
}