@charset 'utf-8';

.greeting {
  .container {
    .row {
      .col-sm-4 {
        padding: 0 25px 0 25px;
      }
    }

    .sign {
      font-weight: 700;
    }
  }

  // ---------------------------------------------------------------------------- //
  // ------------------------------- screen-xs-max ------------------------------ //
  // ---------------------------------------------------------------------------- //
  @media (max-width: $screen-xs-max) {
    .container {
      .row {
        .col-sm-4 {
          padding: 0 70px 70px;
        }
      }
    }
  }
}
