@charset 'utf-8';

.service {
  section {
    .image {
      background-size: cover;
      height: 200px;
      margin: 0 auto 11px;
      overflow: hidden;
      width: 100%;

      img {
        border-top: 4px solid #06a4cc;
        width: 100%;
      }
    }

    .image01 {
      background-image: url(../img/img-service01.jpg);
      background-position: 50% 75%;
    }

    .image02 {
      background-image: url(../img/img-service02.jpg);
      background-position: 50% 63%;
    }

    .image03 {
      background-image: url(../img/img-service03.jpg);
      background-position: 50% 17%;
    }

    .image04 {
      background-image: url(../img/img-service04.jpg);
      background-position: 50% 33%;
    }

    section {
      background-color: #fdfdfd;
      border-bottom: 1px solid #d6d6d6;
      padding-bottom: 40px;

      .container {
        .content {
          margin: 0 auto;
          max-width: 700px;

          .p-page {
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }

      &:not(:last-child) {
        margin-bottom: 120px;
      }
    }
  }

// ---------------------------------------------------------------------------- //
// ------------------------------- screen-xs-max ------------------------------ //
// ---------------------------------------------------------------------------- //
  @media (max-width: $screen-xs-max) {
    section {
      .image {
        background-size: cover;
        height: 100px;
        margin: 0 auto 11px;
        overflow: hidden;
        width: 100%;
      }

      section {
        &:not(:last-child) {
          margin-bottom: 60px;
        }
      }
    }
  }
}
