@charset 'utf-8';

.about-us {
  .container {
    padding: 0 100px;

    table {
      tbody {
        tr {
          th {
            min-width: 110px;
          }

          td {
            .origin {
              @include font-setting(1.1rem, 1.6);
              border-top: 1px solid #ccc;
              display: block;
              margin-top: 10px;
              padding-top: 5px;
            }

            .telLink {
              color: $color-default;
              cursor: default;
            }
          }
        }
      }
    }

    .gmap {
      margin-bottom: 50px;
      position: relative;
      padding-bottom: 56.25%;
      height: 0;
      overflow: hidden;

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }

  // ---------------------------------------------------------------------------- //
  // ------------------------------- screen-xs-max ------------------------------ //
  // ---------------------------------------------------------------------------- //
  @media (max-width: $screen-xs-max) {
    .container {
      padding: 0 15px;

      table {
        tbody {
          tr {
            th,
            td {
              display: block;
            }

            th {
             background-color: #f9f9f9;
            }

            td {
              background-color: #fff;
            }
          }
        }
      }

      .table>tbody>tr>td,
      .table>tbody>tr>th,
      .table>tfoot>tr>td,
      .table>tfoot>tr>th,
      .table>thead>tr>td,
      .table>thead>tr>th {
        padding: 4px 13px;
        border-bottom: none;
        border-left: none;
        border-right: none;
      }

      .table tr:first-child th {
        border-top: 0;
      }

      .gmap {
        margin-bottom: 30px;
      }

      .gallery {
        .col-xs-6 {
          &:nth-child(2n) {
            padding-left: 7px;
          }

          &:nth-child(2n + 1) {
            padding-right: 7px;
          }
        }

        img {
          margin-bottom: 10px;
        }
      }
    }
  }
}
