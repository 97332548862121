@charset 'UTF-8';

// ******************* variables ******************* //
// color ----------------------------------
$color-default: #555;
$color-black01: #000;
$color-corporate01: #3866b0;
$color-corporate02: #06a4cc;
$color-corporate03: #969fa7;
$color-red01: #c10000;
$color-white01: #fff;
$color-gray01: #f5f5f5;
$color-gray02: #ccc;
$color-gray03: #c5c5c5;
$color-gray04: #efefef;

// others ---------------------------------

// mixin ----------------------------------
@mixin font-setting(
    $font-size,
    $line-height: $line-height-base,
    $font-weight: normal
  ) {
  font-size: $font-size;
  line-height: $line-height;
  font-weight: $font-weight;
}

// ******************** utilities ******************** //
// class ----------------------------------
.none {
  display: none;
}

.mrb0 {
  margin-bottom: 0;
}

.mrb5 {
  margin-bottom: 5px;
}

.mrb20 {
  margin-bottom: 20px;
}

.mrb25 {
  margin-bottom: 25px;
}

.mrb50 {
  margin-bottom: 50px;
}

.mrb100 {
  margin-bottom: 100px;
}

.mr0a {
  margin: 0 auto;
}

.underline {
  text-decoration: underline;
}

.bold {
  font-weight: 700;
}

.ul-unstyled {
  list-style-type: none;
  padding-left: 0;
}

.ul-lower-alpha {
  list-style-type: lower-alpha;
}

.row-eq-height {
  display: flex;
}

.row-eq-height--center {
  align-items: center;
}

.fade-in {
  animation-duration: .5s;
  animation-name: fade-in;
  animation-fill-mode: forwards;
  transition-timing-function: ease-in;
}

.fade-out {
  animation-duration: .5s;
  animation-name: fade-out;
  opacity: 0;
  transition-timing-function: ease-in;
}

.link-hover {
  transition: opacity .2s ease-in;

  &:hover {
    opacity: .7;
  }
}

.current {
  opacity: .7;
}

.proviso {
  padding-left: 1em;
  position: relative;

  &::before {
    color: $color-red01;
    content: '＊';
    left: 0;
    position: absolute;
  }
}

.require {
  background-color: $color-red01;
  border-radius: 4px;
  color: $color-white01;
  font-size: 90%;
  margin-right: 5px;
  padding: 2px 3px;
}

.alert {
  color: $color-red01;
}

// animation -------------------------------
@keyframes fade-in {
  0% {opacity: 0;}
  100% {opacity: 1;}
}

@keyframes fade-out {
  0% {opacity: 1;}
  100% {opacity: 0;}
}

// ***************** scaffolding ***************** //
// links ----------------------------------
a {
  &:hover,
  &:focus {
    text-decoration: none;
  }
}

// ******************** body ********************* //
body {
  color: $color-default;
  font-family: $font-family-sans-serif;
}

// ******************* header ******************* //
header {
  background-color: $color-gray01;
  border-bottom: 3px solid $color-corporate01;
  box-shadow: 0 3px 3px $color-gray03;
  margin-bottom: 30px;
  padding: 20px 0 10px;
  position: relative;

  .border-design {
    background-color: $color-corporate02;
    height: 10px;
    position: absolute;
    top: 0;
    width: 100%;

    &::after,
    &::before {
      content:'';
      height: 10px;
      position: absolute;
      width: calc(100% / 3);
      z-index: 1;
    }

    &::after {
      background-color: $color-corporate03;
      content:'';
      right: 0;
    }

    &::before {
      content:'';
      background-color: $color-corporate01;
      left: 0;
    }
  }

  .container {
    .head {
      align-items: center;
      display: flex;
      justify-content: start;

      .head__logo {
        margin-right: 50px;

        a {
          img {
            width: 180px;
          }
        }
      }

      .head__global-nav {
        font-size: 1.5rem;

        ul {
          margin-bottom: 0;

          li {
            float: left;

            &:not(:last-child) {
              padding-right: 15px;
            }

            a {
              color: $color-black01;
            }
          }
        }
      }
    }
  }
}

// ******************* footer ******************* //
footer {
  background-color: $color-gray01;
  border-top: 1px solid $color-gray02;
  margin-top: 150px;

  .container {
    .foot {
      padding: 10px 0;

      .row {
        align-items: center;

        .col-md-7 {
          .foot__logo {
            align-items: center;
            display: flex;

            a {
              margin-right: 10px;

              img {
                width: 108px;
              }
            }
          }

          .foot__address {
            @include font-setting(1.2rem, 1.4);

            p {
              margin-bottom: 0;
            }

            .foot__address--tel {
              a {
                color: $color-default;
              }
            }
          }
        }

        .col-md-5 {
          .foot__global-nav {
            font-size: 1.2rem;

            ul {
              margin-bottom: 0;

              li {
                float: right;

                &:not(:first-child) {
                  padding-right: 15px;
                }

                a {
                  color: $color-default;
                }
              }
            }
          }
        }
      }
    }
  }

  .copy {
    @include font-setting(1.2rem, 1);
    background-color: $color-default;
    color: #fff;
    margin-bottom: 0;
    padding: 10px 0;
  }
}

// ****************** scroll top ***************** //
.scroll-top {
  background: #555;
  border-radius: 5px;
  bottom: 10px;
  cursor: pointer;
  opacity: 0;
  padding: 6px 10px 10px;
  position: fixed;
  right: 20px;
  width: 50px;
  z-index: 3;
}

html,
body {
  height: calc(100% - 101px);
  margin: 0;
}
main {
  margin-bottom: -62px;
  min-height: calc(100% - 101px);
}

// ******************** title ******************** //
// title ----------------------------------
.h1-page {
  @include font-setting(3.4rem, 1.8, 700);
  color: $color-corporate01;
  margin-bottom: 40px;
}

.h2-page {
  @include font-setting(2.8rem, 1.2, 700);
  color: $color-default;
  border-bottom: 1px solid $color-gray02;
  margin-bottom: 25px;
  padding-bottom: 10px;
}

.p-page {
  @include font-setting(1.4rem, 1.8);
}

.ol-page,
.ul-page {
  @include font-setting(1.4rem, 1.8);
  background-color: $color-gray04;
  margin-left: 1px;
  padding: 9px 10px 9px 30px;
}

.ol-page-bold,
.ul-page-bold {
  font-size: 1.3rem;
  font-weight: 700;
}

.dl-page {
  @include font-setting(1.4rem, 1.8);
}

.table {
  tbody {
    tr {
      th,
      td {
        @include font-setting(1.4rem, 1.8);
      }

      th {
        font-weight: 700;
        vertical-align: middle;
        white-space: nowrap;
      }
    }
  }
}

// ---------------------------------------------------------------------------- //
// ------------------------------- screen-xs-max ------------------------------ //
// ---------------------------------------------------------------------------- //
@media (max-width: $screen-xs-max) {
  main {
    margin-bottom: 0;
  }

  // ******************* header ******************* //
  header {
    margin-bottom: 15px;

    .container {
      .head {
        .head__logo {
          a {
            img {
              width: 100px;
            }
          }
        }

        .head__global-nav {
          .hamburger {
            cursor: pointer;
            height: 40px;
            position: absolute;
            right: 15px;
            top: 10px;
            width: 52px;
            z-index: 300;

            .hamburger__ttl {
              @include font-setting(1.2rem, 1);
              display: block;
              margin-top: 8px;
              text-align: center;
            }

            .hamburger__line {
              background-color: #111;
              height: 1px;
              left: 11px;
              position: absolute;
              transition: all .6s;
              width: 30px;
            }

            .hamburger__line--1 {
              top: 24px;
            }

            .hamburger__line--2 {
              top: 34px;
            }

            .hamburger__line--3 {
              top: 44px;
            }
          }

          .black-bg {
            align-items: center;
            background-color: hsla(217, 52%, 45%, 0.95);;
            cursor: pointer;
            display: flex;
            height: 100vh;
            left: 0;
            opacity: 0;
            position: fixed;
            top: 0;
            transition: all .2s;
            visibility: hidden;
            width: 100vw;
            z-index: 100;

            .ul-outer {
              align-items: center;
              display: flex;
              height: 100vh;
              justify-content: center;
              width: 100vw;

              ul {
                text-align: center;

                li {
                  float: none;
                  padding: 0;
                  width: 100%;

                  a {
                    @include font-setting(2.2rem, 2.2, bold);
                    color: $color-white01;
                  }
                }

                .close {
                  @include font-setting(2.2rem, 2.2, bold);
                  background-color: $color-white01;
                  color: $color-black01;
                  margin-top: 30px;
                }
              }
            }
          }
        }
      }
    }
  }

  // ******************* footer ******************* //
  footer {
    margin-top: 75px;

    .container {
      .foot {
        .row-eq-height {
          display: block;

          .col-md-7 {
            .foot__logo {
              a {
                img {
                  width: 80px;
                }
              }
            }
          }

          div {
            nav {
              margin-top: 15px;
            }
          }
        }
      }
    }

    .copy {
      @include font-setting(1rem, 1);
    }
  }

  // ******************** title ******************** //
  // title ----------------------------------
  .h1-page {
    @include font-setting(2.5rem, 1.8, 700);
    margin-bottom: 20px;
  }

  .h2-page {
    @include font-setting(2rem, 1.2, 700);
    margin-bottom: 15px;
  }

  .p-page {
    @include font-setting(1.2rem, 1.8);
  }

  .ol-page,
  .ul-page {
    @include font-setting(1.2rem, 1.8);
    padding: 9px 10px 9px 25px;
  }

  .ol-page-bold,
  .ul-page-bold {
    font-size: 1.1rem;
    font-weight: 700;
  }

  .dl-page {
    @include font-setting(1.2rem, 1.8);
  }


  // ******************** others ******************* //
  .spN01 {
    display: none;
  }

  // ****************** scroll top ***************** //
  .scroll-top {
    bottom: 130px;
  }
}
