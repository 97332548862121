@charset 'utf-8';

.info-page {
  .container {
    max-width: 600px; 

    p {
      @include font-setting(1.4rem, 1.8);
    }
  }
}
